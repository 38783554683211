label {
  color: #263a5b;
}
.form-control {
  border-color: rgba(206, 212, 218, 0.8);
}
.input-group-text {
  line-height: 1;
}

.rightbar {
  overflow-y: auto;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0.5rem;
}
::-webkit-scrollbar-thumb {
  background: #0080ff;
  border-radius: 0.5rem;
}
::-webkit-scrollbar-thumb:hover {
  background: #0080ff;
}

.table-bordered {
  border-color: rgba(206, 212, 218, 0.6);
}

.table-bordered td {
  border-color: rgba(206, 212, 218, 0.6);
}
.table-bordered th {
  border-color: rgba(206, 212, 218, 0.6);
}

element.style {
}
.table-responsive {
  overflow-x: hidden;
}

.contentbar {
  margin-bottom: 3.75rem;
}
