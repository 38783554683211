.download-btn {
  text-align: center;
}
@media (min-width: 767px) {
  .download-btn {
    text-align: start;
  }
}

.dashboard-bg {
  background-color: #ffffff;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-radius: 0.1875rem;
}

.dashboard-message {
  font-size: 1.25rem;
  margin-bottom: 0;
  text-transform: capitalize;
  text-align: center;
}

.dashboard-row {
  max-width: 90rem;
  margin: 0 auto;
}
