.form-center-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
}

.invalid-feedback {
  display: inline-block;
}

@media (min-width: 768px) {
  .modal-dialog {
    max-width: 43.75rem;
  }
}

.update-user-form {
  max-width: 68.75rem;
  margin: 0 auto;
  background-color: white;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 0.1875rem;
}
