.form-center-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
}

.invalid-feedback {
  display: inline-block;
}

@media (min-width: 768px) {
  .modal-dialog {
    max-width: 700px;
  }
}

.modal-header-danger {
  background-color: #dc3545;
  border-color: #dc3545;
}
.modal-text {
  color: #263a5b;
}
