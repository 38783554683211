.topbar-center {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .topbar-center {
    justify-content: flex-end;
  }
}

.topbar-mobile .mobile-togglebar div.topbar-toggle-hamburger {
  padding: 10px 10px 12px;
  border-radius: 3px;
  height: 40px;
}
.topbar-mobile .mobile-togglebar div.topbar-toggle-hamburger img {
  width: 20px;
  filter: invert(0.6) sepia(1) saturate(1) hue-rotate(185deg);
}
.topbar-mobile
  .mobile-togglebar
  div.topbar-toggle-hamburger
  img.menu-hamburger-vertical {
  display: none;
}
.topbar-mobile .mobile-togglebar div.topbar-toggle-hamburger:hover {
  background-color: rgba(129, 167, 205, 0.1);
}
.topbar-mobile .mobile-togglebar div.topbar-toggle-hamburger:hover img {
  filter: invert(0.7) sepia(1) saturate(14) hue-rotate(195deg);
}

.topbar-mobile .mobile-togglebar .menu-hamburger {
  height: 40px;
}

.profile-icon,
.menubar,
.topbar-toggle-hamburger {
  cursor: pointer;
}

@media (max-width: 991px) {
  .togglebar .menubar {
    margin-top: 0;
  }
}
.topbar .togglebar .menu-hamburger {
  padding: 10px;
}

.live-icon-urgent {
  background-color: #ff3f3f !important;
}
.live-icon-urgent:hover {
  background-color: #ff3f3f !important;
}

.notification-urgent {
  background-color: #ff81817a;
}
.notification-urgent:hover {
  background-color: #ff81817a !important;
}
.infobar-icon:hover .notification-svg-urgent {
  filter: invert(0.71) sepia(0.28) saturate(23) hue-rotate(316deg)
    brightness(1.27) contrast(1.52) !important;
}
.notification-button {
  text-align: center;
}
