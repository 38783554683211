.login-logo {
  margin: 1rem auto;
}
.login-label {
  width: 100%;
  text-align: start;
  margin-top: 1rem;
  padding: 0 0.25rem;
}
.auth-box .auth-box-right .form-head img {
  width: 200px;
}
