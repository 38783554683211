/* 
-----------------------------
    : Custom - Menu css :
-----------------------------
*/
.vertical-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
.vertical-menu > li {
  position: relative;
  margin: 0;
  padding: 0;
}
.vertical-menu > li > div {
  padding: 10px 22px;
  display: block;
  color: #263a5b;
  cursor: pointer;
}
.vertical-menu > li > div > i {
  display: inline-block;
  width: 30px;
  font-size: 16px;
  vertical-align: middle;
}
.vertical-menu > li > div > img {
  display: inline-block;
  width: 20px;
  vertical-align: middle;
  margin-right: 10px;
  filter: invert(0.6) sepia(1) saturate(1) hue-rotate(185deg);
}
.vertical-menu > li > div > span {
  vertical-align: middle;
}
.vertical-menu > li > div.active {
  color: #0080ff;
}
.vertical-menu > li > div.active img {
  filter: invert(0.7) sepia(1) saturate(14) hue-rotate(195deg);
}
.vertical-menu > li:hover > div {
  background-color: transparent;
  color: #0080ff;
  opacity: 1;
}
.vertical-menu > li:hover > div img {
  filter: invert(0.7) sepia(1) saturate(14) hue-rotate(195deg);
}
.vertical-menu > li.active > div {
  font-weight: 400;
  background-color: transparent;
  color: #0080ff;
  opacity: 1;
}
.vertical-menu > li.active > div img {
  filter: invert(0.7) sepia(1) saturate(14) hue-rotate(195deg);
}
.vertical-menu > li.active {
  background-color: transparent;
}
.vertical-menu > li .label,
.vertical-menu > li .badge {
  margin-top: 4px;
}
.vertical-menu li.vertical-header {
  padding: 15px 25px 5px 25px;
  color: #8a98ac;
  text-transform: uppercase;
  font-size: 11px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.vertical-menu li:first-child.vertical-header {
  padding: 5px 25px 5px 25px;
}
.vertical-menu li > div > .icon-chevron-right {
  width: auto;
  height: auto;
  padding: 0;
  font-size: 14px;
  line-height: 24px;
  -webkit-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}
.vertical-menu li.active > div > .icon-chevron-right {
  transform: rotate(90deg);
}
.vertical-menu li.active > .vertical-submenu {
  display: block;
  /* overflow: visible; */
  max-height: 400px;
  opacity: 1;
  /* transition: max-height 1s, opacity 1s; */
}
.vertical-menu div {
  color: #8a98ac;
  text-decoration: none;
}
.vertical-menu .vertical-submenu {
  display: block;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: max-height 1s, opacity 1s;
  /* display: none; */
  list-style: none;
  padding-left: 5px;
  padding: 5px 0 5px 5px;
  margin: 0 1px;
  background: transparent;
}
.vertical-menu .vertical-submenu .vertical-submenu {
  padding-left: 15px;
}
.vertical-menu .vertical-submenu > li > div {
  padding: 8px 20px 8px 45px;
  display: block;
  font-size: 14px;
  color: #263a5b;
  cursor: pointer;
}
.vertical-menu .vertical-submenu > li > div > .mdi {
  font-size: 5px;
  margin-right: 10px;
  vertical-align: middle;
  color: #263a5b;
}
.vertical-menu .vertical-submenu > li > div > .icon-chevron-right,
.vertical-menu .vertical-submenu > li > div > .icon-chevron-down {
  width: auto;
}
.vertical-menu .vertical-submenu > li > div:before {
  content: "";
  font-family: "feather" !important;
  font-size: 10px;
  line-height: 10px;
  padding-right: 0.2rem;
  vertical-align: middle;
}
.vertical-menu .vertical-submenu > li > div.active {
  color: #0080ff;
}
.vertical-menu .vertical-submenu > li.active > div {
  opacity: 1;
  font-weight: 400;
  color: #0080ff;
}
.vertical-menu .vertical-submenu > li.active > div > .mdi {
  opacity: 1;
  color: #0080ff;
}
.vertical-menu .vertical-submenu > li > div:hover {
  opacity: 1;
  color: #0080ff;
}
.vertical-menu .vertical-submenu > li > div:hover > .mdi {
  opacity: 1;
  color: #0080ff;
}

@media (min-width: 768px) {
  .vertical-layout.toggle-menu .vertical-menu > li > div > i {
    font-size: 18px;
  }
  .vertical-layout.toggle-menu .vertical-menu > li > div {
    padding: 10px 30px;
  }
  .vertical-layout.toggle-menu .vertical-menu > li > div > span {
    display: none;
  }
  .vertical-layout.toggle-menu .vertical-menu li > div > .icon-chevron-right {
    display: none;
  }
}
