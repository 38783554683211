.pagination .page-item {
  cursor: pointer;
}
.pagination .page-item.disabled {
  cursor: initial;
}
.table-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  padding-top: 0;
}
.table-body .card-subtitle {
  margin: 0;
}

.table-scroll {
  overflow: auto;
}

.table-scroll::-webkit-scrollbar {
  height: 10px;
}

.table-scroll::-webkit-scrollbar-track {
  background: #e7ecf2;
  border-radius: 50px;
  width: 2px;
}

.table-scroll::-webkit-scrollbar-thumb {
  background: #d4d8de;
  border-radius: 50px;
}

.icons-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.fa-edit {
  color: #18d26b;
  cursor: pointer;
}
.fa-trash {
  color: #ff3f3f;
  cursor: pointer;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #e7ecf2;
}
