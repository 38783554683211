.package-selection-bg {
  margin-bottom: 1.25rem;
  background-color: #ffffff;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-radius: 0.1875rem;
}

.form-check-inline {
  min-width: 60px;
}
.form-check-input,
.form-check-label {
  cursor: pointer;
}

.card-body-error {
  border-color: #ff3f3f !important;
}
