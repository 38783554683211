/* Chrome, Safari, Edge, Opera */
.price-tag::-webkit-outer-spin-button,
.price-tag::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.price-tag[type="number"] {
  -moz-appearance: textfield;
}

.price-error {
  border-color: #ffd8d8;
  background-color: #ffd8d8;
  color: #ff3f3f;
}
.price-error-input {
  border-color: #ffd8d8;
}
